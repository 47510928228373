import React from 'react';
// import AirbrakeClient from 'airbrake-js';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

const nonLogEnvironment = 'development';

const ErrorDisplay = ({ error, silent }) => {
  if (silent) {
    return null;
  }
  return (
    <div>
      <h1>Skylark We Have a Problem</h1>
      <pre>{JSON.stringify(error, null, '  ')}</pre>
    </div>
  );
};

ErrorDisplay.propTypes = {
  error: PropTypes.instanceOf(Object),
  silent: PropTypes.bool,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { hasError: false, error: null };
    this.state = { eventId: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // return { hasError: true, error };
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    // if (process.env.NODE_ENV !== nonLogEnvironment && !this.props.silent) {
    //   const airbrake = new AirbrakeClient({ projectId: 161059, projectKey: 'fb8f03e903cf8967c107d48ff5bb34f9' });
    //   airbrake.notify({ error, params: { errorInfo } });
    // }
  }

  render() {
    const { hasError, error } = this.state;
    const { children, silent } = this.props;
    if (hasError) {
      return <ErrorDisplay silent={silent} error={error} />;
    }
    // when there's not an error, render children untouched
    return children;
  }
}

ErrorBoundary.defaultProps = {
  silent: false,
};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  silent: PropTypes.bool,
};

export default ErrorBoundary;
