const getBoundingBox = (coords) => {
  let bbox = [];
  if (coords && coords.count()) {
    const lats = coords.map(c => c.get('latitude')).sort();
    const lons = coords.map(c => c.get('longitude')).sort();
    bbox = [[lons.first(), lats.first()], [lons.last(), lats.last()]];
  }
  return bbox;
};

export default getBoundingBox;
