import styled from 'styled-components';
import { Marker as GlMarker } from 'react-mapbox-gl';
import { colors, toRgba, variables } from 'styles';

export const Map = styled.div`
  height: 100%;
  position: relative;
  .mapboxgl-map {
    height: 100%;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none;
  }
`;

export const MarkerLink = styled.a`
&.lark-marker {
         color: white;
         background: ${colors.skylarkPurple};
         border: 1px solid white;
         border-radius: 50%;
         padding-left: 1px;
         display: flex;
         justify-content: center;
         align-items: center;
         font-size: 9px;

         cursor: pointer;
         &--zoom-0,
         &--zoom-1,
         &--zoom-2 {
           height: 19px;
           width: 19px;
         }
         &--zoom-3 {
           height: 13px;
           width: 13px;
         }
         &--zoom-4,
         &--zoom-5 {
           height: 15px;
           width: 15px;
         }
         &--zoom-6,
         &--zoom-7,
         &--zoom-8,
         &--zoom-9 {
           height: 10px;
           width: 10px;
         }
         &--zoom-10,
         &--zoom-11,
         &--zoom-12,
         &--zoom-13,
         &--zoom-14,
         &--zoom-15 {
           height: 15px;
           width: 15px;
         }
         &--zoom-16,
         &--zoom-17,
         &--zoom-18,
         &--zoom-19,
         &--zoom-20 {
           height: 20px;
           width: 20px;
         }
         &--hotel,
         &--hotel--selected {
           /* @extend .lark-marker; */
           background: ${colors.blue};
           border: 1px solid ${colors.black};
         }
         &--destination,
         &--country {
           /* @extend .lark-marker; */
           color: ${colors.white};
           background: ${colors.skylarkPurple};
           border: 1px solid ${colors.white};
           height: 19px;
           width: 19px;
           line-height: 19px;
           font-size: 9px;
           &--selected {
             /* @extend .lark-marker; */
             background: ${colors.skylarkPurple};
             border: 1px solid $black;
             box-shadow: 0px 0px 10px 10px rgba(${colors.skylarkPurple}, 0.2);
             animation: pulseMarker 900ms ease-out alternate infinite;
           }
         }
         &--home-page {
           /* @extend .lark-marker; */
           color: ${colors.white};
           background: ${colors.skylarkPurple};
           border: none;
           height: 5px;
           width: 5px;
           line-height: 19px;
           font-size: 0;
         }
         &--global_region {
           /* @extend .lark-marker; */
           height: 30px;
           width: 30px;
           color: ${colors.skylarkPurple};
           font-size: 13px;
           background: ${colors.white};
           border: 1px solid ${colors.skylarkPurple};
         }
         &--level1_admin_area,
         &--level2_admin_area {
           /* @extend .lark-marker; */
           height: 21px;
           width: 21px;
           line-height: 21px;
           // background: darkgreen;
           // border: 1px solid tomato;
         }
         &--country {
           /* @extend .lark-marker; */
           height: 23px;
           width: 23px;
           line-height: 23px;
           font-size: 11px;
           // color: white;
           // background: ${colors.skylarkPurple};
           // border: 1px solid white;
         }
        }
       `;