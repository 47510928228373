import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Immutable from 'immutable';
import colors from 'styles/colors';
import track, { useTracking } from 'react-tracking';

import { MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE_REF } from 'constants/config';
import getBoundingBox from 'components/Mapping/utils/getBoundingBox';
import ReactMapboxGL, { Feature, Layer, Marker, ZoomControl } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import * as Styled from './styled';
// import './markers.scss';

const MapGL = ReactMapboxGL({
  accessToken: MAPBOX_ACCESS_TOKEN,
  scrollZoom: false,
  dragRotate: false,
  maxZoom: 14,
});

const getCirclePaint = {
  'circle-radius': 4,
  'circle-color': colors.skylarkPurple,
  'circle-opacity': 0.8,
};

const PointLink = ({ point, children }) => {
  let targetHref = '';
  const targetUrl = `/destinations/${point.path}`;
  if (point.object_type === 'destination') {
    targetHref = { pathname: '/destinations', query: { destinationPath: point.path } };
  } else {
    targetHref = { pathname: '/hotels/[hotelCode]', query: { destinationPath: point.path, hotelCode: point.slug } };
  }
  return (
    <Link href={targetHref} as={targetUrl} prefetch={false}>
      {children}
    </Link>
  );
};

PointLink.propTypes = {
  point: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};

const MapComponent = ({
  onMapMouseMove,
  points,
  center,
  zoom,
  hoveredPoint,
  pointMouseEnter,
  pointMouseLeave,
  pointClick,
  popupClick,
  markerClassName,
  fitBoundsOptions,
  Popup,
  onLinkClick,
}) => {
  const [fitBounds, setFitBounds] = useState();
  const [mapPoints, setMapPoints] = useState([]);

  useEffect(() => {
    setFitBounds(points && !center ? getBoundingBox(points) : null);
    setMapPoints(points.toJS());
  }, [points, center]);

  const mapBounds = fitBounds && fitBounds.length ? fitBounds : null;
  const zoomLevel = zoom ? parseInt(zoom[0], 10) : 1;

  return (
    <Styled.Map>
      <MapGL
        zoom={zoom}
        style={MAPBOX_STYLE_REF}
        center={center && center.length ? center : undefined}
        onMouseMove={onMapMouseMove}
        fitBounds={mapBounds}
        fitBoundsOptions={fitBoundsOptions}
      >
        <ZoomControl zoomDiff={1} />

        {mapPoints.length > 100 ? (
          <Layer type="circle" paint={getCirclePaint}>
            {mapPoints.map((point) => (
              <Feature
                key={point.iata_code}
                coordinates={[point.longitude, point.latitude]}
                onMouseEnter={() => pointMouseEnter(Immutable.fromJS(point))}
                onMouseLeave={() => pointMouseLeave()}
                onClick={() => pointClick(Immutable.fromJS(point))}
              />
            ))}
          </Layer>
        ) : (
          mapPoints.map((point) => (
            <Marker
              key={`${point.object_type}|${point.access_code || point.iata_code || point.code || point.path}`}
              onMouseEnter={() => pointMouseEnter(Immutable.fromJS(point))}
              onMouseLeave={() => pointMouseLeave()}
              coordinates={[point.longitude, point.latitude]}
              // onClick={() => pointClick(Immutable.fromJS(point))}
              // onClick={(e) => e.preventDefault()}
            >
              <PointLink point={point}>
                <Styled.MarkerLink
                  onClick={() => onLinkClick(point)}
                  className={`${markerClassName} lark-marker lark-marker--zoom-${zoomLevel} lark-marker--${point.object_type}`}
                >
                  {point.child_count || (point.object_type === 'hotel' ? null : 1)}
                </Styled.MarkerLink>
              </PointLink>
            </Marker>
          ))
        )}
        <Popup
          point={hoveredPoint}
          anchor="bottom"
          offset={mapPoints.length > 50 ? 0 : 15}
          pointOnClick={(point) => {
            popupClick(point);
          }}
        />
      </MapGL>
    </Styled.Map>
  );
};

MapComponent.defaultProps = {
  onMapMouseMove: () => null,
  fitBoundsOptions: {
    padding: {
      top: 50,
      bottom: 50,
      left: 50,
      right: 50,
    },
  },
  hoveredPoint: Immutable.Map(),
  points: Immutable.List,
  zoom: [1],
  Popup: () => null,
  pointMouseLeave: () => null,
  pointMouseEnter: () => null,
  pointClick: () => null,
  popupClick: () => null,
  onLinkClick: () => null,
};

MapComponent.propTypes = {
  onMapMouseMove: PropTypes.func,
  pointMouseEnter: PropTypes.func,
  pointMouseLeave: PropTypes.func,
  pointClick: PropTypes.func,
  popupClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  fitBoundsOptions: PropTypes.instanceOf(Object),
  hoveredPoint: PropTypes.instanceOf(Immutable.Map),
  points: PropTypes.instanceOf(Immutable.List),
  zoom: PropTypes.arrayOf(PropTypes.number),
  markerClassName: PropTypes.string,
  center: PropTypes.arrayOf(PropTypes.number),
  Popup: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

export default MapComponent;
