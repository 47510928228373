import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import ErrorBoundary from 'components/ErrorBoundary';

import { setSelectedPoint, setHoveredPoint, clearSelectedPoint, clearHoveredPoint } from 'modules/places';

import Map from './Map';

import * as Styled from './styled';

export class Mapping extends React.Component {
  constructor() {
    super();
    this.previousZoom = [0];
  }

  state = {
    zoom: [1],
  };

  onMapZoom(map) {
    const zoomHasChanged = this.previousZoom[0] !== parseInt(map.getZoom(), 10);
    if (zoomHasChanged) {
      this.setState({ zoom: [map.getZoom()] });
      this.previousZoom = [parseInt(map.getZoom(), 10)];
    }
  }

  pointClicked(point) {
    // console.log("MAPPING.JSX > pointClicked", point && point.toJS());
    const { doClearHoveredPoint, doSetSelectedPoint, onPointClick } = this.props;
    doClearHoveredPoint();
    doSetSelectedPoint(point);
    onPointClick(point);
  }

  render() {
    // const props = this.props;
    const { zoom } = this.state;
    const { hoveredPoint, placeLoading, placeLoaded, className, Popup, markerClassName, points, doSetHoveredPoint, doClearHoveredPoint } = this.props;

    return (
      <ErrorBoundary silent>
        <Styled.Mapping loaded={placeLoaded}>
          <Map
            onZoom={(mapInstance) => {
              setTimeout(() => {
                this.onMapZoom(mapInstance);
              }, 1000);
            }}
            onMapMouseMove={() => {
              doClearHoveredPoint();
            }}
            pointClick={(point) => {
              this.pointClicked(point);
            }}
            pointMouseEnter={(point) => {
              doSetHoveredPoint(point);
            }}
            pointMouseLeave={() => {
              doClearHoveredPoint();
            }}
            zoom={zoom}
            containerStyle={{ height: '100%', minHeight: 250 }}
            points={points}
            hoveredPoint={hoveredPoint}
            markerClassName={markerClassName}
            Popup={Popup}
          />
        </Styled.Mapping>
      </ErrorBoundary>
    );
  }
}

// isRequired props have defaults in place reducer
Mapping.propTypes = {
  className: PropTypes.string,
  points: PropTypes.instanceOf(Immutable.List),
  markerClassName: PropTypes.string,
  doSetSelectedPoint: PropTypes.func.isRequired,
  doSetHoveredPoint: PropTypes.func.isRequired,
  doClearHoveredPoint: PropTypes.func.isRequired,
  hoveredPoint: PropTypes.instanceOf(Immutable.Map).isRequired,
  onPointClick: PropTypes.func,
  placeLoading: PropTypes.bool.isRequired,
  placeLoaded: PropTypes.bool.isRequired,
  Popup: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

Mapping.defaultProps = {
  className: null,
  points: Immutable.List(),
  onPointClick: () => null,
  Popup: () => <div>Popup</div>,
  markerClassName: '',
};

const mapStateToProps = (state, props) => ({
  place: state.place.getIn(['items', props.accessCode]) || null,
  selectedPoint: state.place.get('selectedPoint'),
  hoveredPoint: state.place.get('hoveredPoint'),
  placeLoading: state.place.get('loading'),
  placeLoaded: state.place.get('loaded'),
});

const mapDispatchToProps = {
  doSetHoveredPoint: setHoveredPoint,
  doSetSelectedPoint: setSelectedPoint,
  doClearHoveredPoint: clearHoveredPoint,
  doClearSelectedPoint: clearSelectedPoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);
